/* eslint-disable immutable/no-mutation */
/* eslint-disable immutable/no-this */

export class Error {
   private setButtonRedirectLogin() {
      const button = `
         <div style="padding: 33px 0 0">
            <button name="back-to-login" type="button" data-i18="back-to-login" class="btn submitButton-customizable-outline" 
               onclick="window.location.href = window.location.origin.concat('/login')">
               back to login
            </button>
         </div>`

      const modalBodyCollection = document.getElementsByClassName('modal-body')

      const modalBody = Array.from(modalBodyCollection)

      modalBody.forEach((modal) => {
         modal.lastElementChild.insertAdjacentHTML('afterend', button)
      })
   }

   public init() {
      this.setButtonRedirectLogin()
   }
}
