/* eslint-disable immutable/no-mutation */
/* eslint-disable immutable/no-this */

import { Commons } from '../commons'

export class MFA {
   private setHeader() {
      const header = document.createElement('div')
      header.innerHTML = `
         <div>
            <div>
               <div class="banner-customizable">
                  <center>
                  </center>
               </div>
            </div>
         </div>
      `

      const modalCollection = document.getElementsByClassName('modal-body')
      const modalBody = Array.from(modalCollection)

      modalBody.forEach((modal) => {
         modal.parentElement.prepend(header)
      })

      const commons = new Commons()
      commons.setHeaderLogo()
   }

   private setInputs() {
      const MFACollection = document.getElementsByName('authentication_code')
      const items = Array.from(MFACollection)

      items.forEach((item) => {
         item.classList.add('input-code')
         item.classList.remove('inputitem-customizable')
         item.setAttribute('type', 'text')
         item.setAttribute('maxlength', '6')
      })
   }

   private setButtonRedirectLogin() {
      const button = `
         <div style="padding: 0 18px">
            <button name="back-to-login" type="button" data-i18="back-to-login" class="btn submitButton-customizable-outline" 
               onclick="window.location.href = window.location.origin.concat('/logout').concat(window.location.search)">
               back to login
            </button>
         </div>`
      const formCollection = document.getElementsByTagName('form')

      const forms = Array.from(formCollection)

      forms.forEach((form) => {
         form.insertAdjacentHTML('afterend', button)
      })
   }

   public init() {
      this.setHeader()
      this.setInputs()
      this.setButtonRedirectLogin()
   }
}
