/* eslint-disable immutable/no-this */
/* eslint-disable immutable/no-mutation */

import { MFA } from './mfa'
import { Error } from './error'
import { Login } from './login'
import { Commons } from './commons'
import { ForgotPassword } from './forgot-password'
import { ConfirmForgotPassword } from './confirmForgotPassword'

class Main {
   pathname: string

   constructor() {
      this.pathname = window.location.pathname
   }

   getPathname() {
      return this.pathname
   }

   commonInitial() {
      const commons = new Commons()
      commons.initialize()
   }
}

window.onload = function () {
   const init = new Main()

   init.commonInitial()

   switch (init.getPathname()) {
      case '/login':
         return new Login().init()
      case '/forgotPassword':
         return new ForgotPassword().init()
      case '/confirmForgotPassword':
         return new ConfirmForgotPassword().init()
      case '/mfa':
         return new MFA().init()
      case '/error':
         return new Error().init()
      default:
         return console.error('Unexpected route')
   }
}
