/* eslint-disable immutable/no-mutation */
/* eslint-disable immutable/no-this */

import { Commons } from '../commons'

export class ForgotPassword {
   private setInputs() {
      const username = document.getElementById('username')

      username.setAttribute('autocomplete', 'off')
      username.classList.remove('inputField-customizable')

      const formCollection = document.getElementsByTagName('form')
      const form = Array.from(formCollection)

      const label = document.createElement('label')
      label.innerText = 'Username or e-mail'

      const div = document.createElement('div')
      div.classList.add('label-float', 'm-t-30')

      div.append(username)
      div.append(label)

      form.forEach((item) => {
         item.prepend(div)
      })
   }

   private setSubTitle() {
      const spanCollection = document.getElementsByTagName('span')
      const spans = Array.from(spanCollection)

      spans.forEach((span) => {
         span.innerText =
            'Enter your Username or E-mail below and we will send a message to reset your password.'
      })
   }

   private setButtonRedirectLogin() {
      const button = `
         <button name="back-to-login" type="button" data-i18="back-to-login" class="btn submitButton-customizable-outline" 
            onclick="window.location.href = window.location.origin.concat('/login')">
            back to login
         </button>`
      const formCollection = document.getElementsByTagName('form')

      const forms = Array.from(formCollection)

      forms.forEach((form) => {
         form.insertAdjacentHTML('afterend', button)
      })
   }

   public init() {
      const commons = new Commons()

      this.setInputs()
      this.setSubTitle()
      this.setButtonRedirectLogin()

      commons.setReCaptcha()
   }
}
