/* eslint-disable immutable/no-this */
/* eslint-disable immutable/no-mutation */

import { processForm } from '../utils'

export class Commons {
   private isLogin: boolean

   constructor() {
      this.isLogin = window.location.pathname === '/login'
   }

   private setBGAnimation() {
      const ul: HTMLUListElement = document.createElement('ul')
      const strTagString: string = new Array(10).fill('<li></li>').join('')

      ul.setAttribute('class', 'bg-bubbles')
      ul.innerHTML = strTagString

      document.body.append(ul)
   }

   public setHeaderLogo() {
      const tagImage: HTMLImageElement = document.createElement('img')

      tagImage.setAttribute('alt', 'logo')
      tagImage.setAttribute('src', '/assets/img/logo.svg')

      tagImage.classList.add('logo-customizable', 'logo-link')

      const allTagLogo = document.getElementsByClassName('logo-customizable')
      const logoArr = Array.from(allTagLogo)

      if (!logoArr.length) {
         var banner = document.getElementsByClassName('banner-customizable')
         var bannerArr = Array.from(banner)

         bannerArr.forEach((item) => {
            const items = Array.from(item.children)

            items.forEach((item) => {
               if (item.tagName === 'CENTER' && !item.children.length)
                  item.append(tagImage)
            })
         })
      }
   }

   private setModalBody() {
      const modalBody = document.getElementsByClassName('modal-body')
      const arr = Array.from(modalBody)

      arr.forEach((item) => {
         const childrenArr = Array.from(item.children)

         childrenArr.forEach((child) =>
            child.classList.add('modal-body-children'),
         )
      })
   }

   private validateReCaptcha() {
      const form = document.getElementsByTagName('form')[0]

      if (form.attachEvent) {
         form.attachEvent('submit', (e) => processForm(e, form))
      } else {
         form.addEventListener('submit', (e) => processForm(e, form))
      }
   }

   public setReCaptcha() {
      const bodyId = document.body.getAttribute('id')
      const ref = this.isLogin ? 'signInSubmitButton' : 'reset_my_password'
      const elementRef = document.getElementsByName(ref)

      if (!!elementRef[0] && bodyId.startsWith('sitekey-')) {
         const sitekey = bodyId.replace('sitekey-', '')

         elementRef[0].insertAdjacentHTML(
            'beforebegin',
            `<center><div id="captcha_container" class="g-recaptcha"></div></center>`,
         )

         grecaptcha.render('captcha_container', {
            sitekey,
            hl: localStorage.getItem('language'),
         } as any)

         this.validateReCaptcha()
      }
   }

   public initialize() {
      this.setBGAnimation()
      this.setHeaderLogo()
      this.setModalBody()
   }
}
